/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import { mapGetters } from 'vuex';
export default {
    name: 'route2',
    data() {
        return {
            'first_name': 'Durga' 
        }
    },
    mounted() {
        this.$store.commit('increment');
        alert(this.$store.state.count)
    },
    computed: {
        ...mapGetters({
            getTods: 'doneTodos'
        })

    },
    methods:{
        checkEvent(){
            alert('test');
        }
    }
}

